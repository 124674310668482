<template>
    <div class="maincontent">
        <div class="container">
            <div class="row d-flex justify-content-center mt-4 description">
                <div class="col-12 col-md-8">
                    <div class="row">
                        <div class="col-12">
                            <h3>Außenansichten</h3>
                            <span class="text-muted">Nachfolgenden sind die verschiedenen Außenansichten des Wohnkomplexes dargestellt. <br>
                                In den grünen Kreisen sind jeweils die Bezeichnungen der einzelnen Wohnungen abgebildet. <br></span>
                            <span v-if="screenWidth()" class="text-muted"> Wenn Sie sich für eine bestimmte Wohnung, kommen Sie über einen Klick auf die jeweilige Bezeichnung zu dem Einzelgrundriss der Wohnung.<br>
                                Dort können Sie über das Formular unter dem Grundriss Kontakt zu uns aufnehmen.</span>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12 views">
                            <h3>Außenansicht Nord Innenhof</h3>
                            <img class="imageView" src="@/assets/Bauplaene/GesamtansichtOutside/GesamtansichtNord1.jpg" alt="Gesamtansicht Nord Innenhof">
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_21')" pill variant="outline-secondary" style="position: absolute; top:21%; left:6.2%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_06')" pill variant="outline-secondary" style="position: absolute; top:46%; left:12.2%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_03')" pill variant="outline-secondary" style="position: absolute; top:64.2%; left:12.2%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_01')" pill variant="outline-secondary" style="position: absolute; top:83.5%; left:12.2%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_19')" pill variant="outline-secondary" style="position: absolute; top:46%; left:24.5%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_16')" pill variant="outline-secondary" style="position: absolute; top:64.2%; left:24.5%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_13')" pill variant="outline-secondary" style="position: absolute; top:83%; left:24.5%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_20')" pill variant="outline-secondary" style="position: absolute; top:20.5%; right:30%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_17B')" pill variant="outline-secondary" style="position: absolute; top:45.5%; right:37%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_14B')" pill variant="outline-secondary" style="position: absolute; top:64%; right:37%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_11B')" pill variant="outline-secondary" style="position: absolute; top:82.5%; right:37%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_32B')" pill variant="outline-secondary" style="position: absolute; top:21%; right:6.5%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_30B')" pill variant="outline-secondary" style="position: absolute; top:46%; right:14.5%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_27B')" pill variant="outline-secondary" style="position: absolute; top:64.5%; right:14.5%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_24B')" pill variant="outline-secondary" style="position: absolute; top:83%; right:14.5%" class="specialBtn"></b-button>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12 views">
                            <h3>Außenansicht Süd Richtung U-Bahn</h3>
                            <img class="imageView" src="@/assets/Bauplaene/GesamtansichtOutside/GesamtansichtSued1.jpg" alt="Gesamtansicht Süd">
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_32B')" pill variant="outline-secondary" style="position: absolute; top:29.6%; left:25%" class="specialBtn"> </b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_29')" pill variant="outline-secondary" style="position: absolute; top:47%; left:25%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_26')" pill variant="outline-secondary" style="position: absolute; top:65%; left:25%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_23')" pill variant="outline-secondary" style="position: absolute; top:83%; left:25%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_30B')" pill variant="outline-secondary" style="position: absolute; top:47.3%; left:57.2%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_27B')" pill variant="outline-secondary" style="position: absolute; top:65%; left:57.2%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_24B')" pill variant="outline-secondary" style="position: absolute; top:83%; left:57.2%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_20')" pill variant="outline-secondary" style="position: absolute; top:29.6%; right:7.7%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_17B')" pill variant="outline-secondary" style="position: absolute; top:47%; right:7.7%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_14B')" pill variant="outline-secondary" style="position: absolute; top:65%; right:7.7%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_11B')" pill variant="outline-secondary" style="position: absolute; top:83.5%; right:7.7%" class="specialBtn"></b-button>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12 views">
                            <h3>Außenansicht Süd Richtung Julius-Vossler-Straße</h3>
                            <img class="imageView" src="@/assets/Bauplaene/GesamtansichtOutside/GesamtansichtSued2.jpg" alt="Gesamtansicht Süd">
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_18')" pill variant="outline-secondary" style="position: absolute; top:48%; left:6.6%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_15')" pill variant="outline-secondary" style="position: absolute; top:65%; left:6.6%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_12')" pill variant="outline-secondary" style="position: absolute; top:83%; left:6.6%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_21')" pill variant="outline-secondary" style="position: absolute; top:29%; left:19.7%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_19')" pill variant="outline-secondary" style="position: absolute; top:46.5%; left:19.7%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_16')" pill variant="outline-secondary" style="position: absolute; top:63.5%; left:19.7%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_13')" pill variant="outline-secondary" style="position: absolute; top:81%; left:19.7%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_06')" pill variant="outline-secondary" style="position: absolute; top:48%; right:56.5%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_03')" pill variant="outline-secondary" style="position: absolute; top:65%; right:56.5%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_01')" pill variant="outline-secondary" style="position: absolute; top:83%; right:56.5%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_09B')" pill variant="outline-secondary" style="position: absolute; top:28.5%; right:30.4%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_07')" pill variant="outline-secondary" style="position: absolute; top:46%; right:30.4%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_04')" pill variant="outline-secondary" style="position: absolute; top:64%; right:30.4%" class="specialBtn"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_02')" pill variant="outline-secondary" style="position: absolute; top:83%; right:30.4%" class="specialBtn"></b-button>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12 views">
                            <h3>Außenansicht Ost</h3>
                            <img class="imageView" src="@/assets/Bauplaene/GesamtansichtOutside/GesamtansichtOst1.jpg" alt="Gesamtansicht Ost">
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_09B')" pill variant="outline-secondary" style="position: absolute; top:26.5%; left:32.5%" class="specialBtnBig"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_07')" pill variant="outline-secondary" style="position: absolute; top:45.5%; left:32.5%" class="specialBtnBig"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_04')" pill variant="outline-secondary" style="position: absolute; top:64.5%; left:32.5%" class="specialBtnBig"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_02')" pill variant="outline-secondary" style="position: absolute; top:84%; left:32.5%" class="specialBtnBig"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_10')" pill variant="outline-secondary" style="position: absolute; top:26.5%; left:61%" class="specialBtnBig"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_08')" pill variant="outline-secondary" style="position: absolute; top:45.5%; left:61%" class="specialBtnBig"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_05')" pill variant="outline-secondary" style="position: absolute; top:65%; left:61%" class="specialBtnBig"></b-button>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12 views">
                            <h3>Außenansicht Ost nach West Innenhof</h3>
                            <img class="imageView" src="@/assets/Bauplaene/GesamtansichtOutside/GesamtansichtOst2.jpg" alt="Gesamtansicht von Ost nach West Innenhof">
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_31')" pill variant="outline-secondary" style="position: absolute; top:21%; right:28.5%" class="specialBtnBig"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_29')" pill variant="outline-secondary" style="position: absolute; top:44.5%; right:20%" class="specialBtnBig"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_25')" pill variant="outline-secondary" style="position: absolute; top:63.5%; right:20%" class="specialBtnBig"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_22')" pill variant="outline-secondary" style="position: absolute; top:82%; right:20%" class="specialBtnBig"></b-button>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12 views">
                            <h3>Außenansicht West</h3>
                            <img class="imageView" src="@/assets/Bauplaene/GesamtansichtOutside/GesamtansichtWest1.jpg" alt="Gesamtansicht West">
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_31')" pill variant="outline-secondary" style="position: absolute; top:26.5%; left:21.3%" class="specialBtnBig"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_28')" pill variant="outline-secondary" style="position: absolute; top:45%; left:26%" class="specialBtnBig"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_25')" pill variant="outline-secondary" style="position: absolute; top:64%; left:26%" class="specialBtnBig"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_22')" pill variant="outline-secondary" style="position: absolute; top:82.5%; left:26%" class="specialBtnBig"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_32B')" pill variant="outline-secondary" style="position: absolute; top:26.5%; right:39.5%" class="specialBtnBig"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_29')" pill variant="outline-secondary" style="position: absolute; top:45%; right:39.5%" class="specialBtnBig"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_26')" pill variant="outline-secondary" style="position: absolute; top:64%; right:39.5%" class="specialBtnBig"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_23')" pill variant="outline-secondary" style="position: absolute; top:82.5%; right:39.5%" class="specialBtnBig"></b-button>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12 views">
                            <h3>Außenansicht West nach Ost Innenhof</h3>
                            <img class="imageView" src="@/assets/Bauplaene/GesamtansichtOutside/GesamtansichtWest2.jpg" alt="Gesamtansicht von West nach Ost Innenhof">
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_10')" pill variant="outline-secondary" style="position: absolute; top:17%; left:24.3%" class="specialBtnBig"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_08')" pill variant="outline-secondary" style="position: absolute; top:43.5%; left:14.5%" class="specialBtnBig"></b-button>
                            <b-button v-if="screenWidth()" @click="goToFlat('WE_05')" pill variant="outline-secondary" style="position: absolute; top:63%; left:14.5%" class="specialBtnBig"></b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'View',
  components: {
  },
    data (){
        return {
            search: true,
            filter: '',
            selectOption: '',
            selectLength: '',
        }
    },
    methods:{
        goToFlat(flatNmb){
            this.$router.push({name:'contact', params:{id:flatNmb}})
        },
        screenWidth(){
            var width = screen.width
            if(width>=576){
                return true
            }else {
                return false
            }
        }
    },
    computed:{
        
    },
    created(){
    }

}
</script>

<style scoped>
.description{
    font-size: 1.2rem;
}
.views{
    position: relative;
}
.imageView{
    max-width: 100%;
}
.specialBtn{
    height: 25px;
    width: 25px;
    display: inline-block;
    cursor: pointer;
    color: transparent !important;
    padding: 1rem 1rem !important;
    border-color: transparent !important;
}
.specialBtn:hover{
    background-color: transparent !important;
}
.specialBtnBig{
    height: 25px;
    width: 25px;
    display: inline-block;
    cursor: pointer;
    color: transparent !important;
    padding: 1.5rem 1.5rem !important;
    border-color: transparent !important;
}
.specialBtnBig:hover{
    background-color: transparent !important;
}
</style>